
import { defineComponent, shallowRef, provide } from 'vue'

// Composables
import { useStorage } from '@/use/local-storage'
import { provideForceReload } from '@/use/force-reload'
import { useDateRange } from '@/use/date-range'
import { useUser } from '@/org/use-users'
import { useProject } from '@/org/use-projects'

// Components
import AppNavigation from '@/system/AppNavigation.vue'
import GlobalSnackbar from '@/components/GlobalSnackbar.vue'
import GlobalConfirm from '@/components/GlobalConfirm.vue'
import AppSearch from '@/components/AppSearch.vue'

// Misc
import { HOUR } from '@/util/fmt/date'

export default defineComponent({
  name: 'App',
  components: {
    AppNavigation,
    GlobalSnackbar,
    GlobalConfirm,
    AppSearch,
  },

  setup() {
    const navigation = useStorage('navigation-drawer', true)

    // Make these global across the app.
    provideForceReload()

    const header = shallowRef(true)
    provide('header', header)

    const footer = shallowRef(true)
    provide('footer', footer)

    const searchVisible = shallowRef(false)

    const dateRange = useDateRange()
    dateRange.changeDuration(HOUR)

    const user = useUser()
    const project = useProject()

    return {
      navigation,
      header,
      footer,
      searchVisible,
      dateRange,

      user,
      project,
    }
  },
})
