
import { defineComponent, computed, PropType } from 'vue'

// Composables
import { useAchievements } from '@/org/use-achievements'
import { Project } from '@/org/use-projects'

export default defineComponent({
  name: 'GetStartedMenu',

  props: {
    project: {
      type: Object as PropType<Project>,
      required: true,
    },
  },

  setup(props) {
    const achievements = useAchievements(computed(() => props.project))

    const numAchievTotal = computed(() => {
      return achievements.items.length
    })

    const numAchievCompleted = computed(() => {
      return achievements.items.filter((achv) => {
        return achv.data && achievements.isCompleted(achv)
      }).length
    })

    return {
      achievements,

      numAchievTotal,
      numAchievCompleted,
    }
  },
})
